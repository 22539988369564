
import { template } from '@shein/common-function'

import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'

const {
  IconPromotionTypeIds, // 活动icon
} = PROMOTION_CONFIG


// mallcode埋点用
export const createMallTagsInfo = (bffGoodsInfo) => {
  let mallTagsInfo = {}
  
  const { language_mall_name, mall_tags, mall_name } = bffGoodsInfo.mallInfoList?.find(mallInfo => mallInfo.mall_code === bffGoodsInfo.mall_code) || {}

  if (!mall_tags) {
    return {
      mallTagsInfo,
      localMallReady: true
    }
  }

  const isOverSeaMall = mall_tags.includes('4')
  const isLocalMall = mall_tags.includes('5')

  Object.assign(mallTagsInfo, { localMall: isLocalMall && !isOverSeaMall, mall_tags, language_mall_name, mall_name })

  return {
    mallTagsInfo,
    localMallReady: true
  }
}

// MARK: 历史低价标签/跟价款标签/保留款标签
export const createHistoricalPrice = (bffGoodsInfo, goodsInfo) => {
  const bffHisLowPriceLabel = bffGoodsInfo?.productMaterial?.hisLowPriceLabel
  
  if (!bffHisLowPriceLabel?.tip || !['lowerPrice', 'followPrice'].includes(bffHisLowPriceLabel.styleType)) return {}

  // web 中间层不同字段   java bff相同字段
  // 同样数据赋值两个不同字段
  const resData = { 
    backgroundColor: bffHisLowPriceLabel.labelBgColor,
    fontColor: bffHisLowPriceLabel.tagColor,
    labelLang: bffHisLowPriceLabel.tip,
    appTraceInfo: bffHisLowPriceLabel.appTraceInfo,
    icon: bffHisLowPriceLabel.icon,
    day: bffHisLowPriceLabel.day
  }

  if (bffHisLowPriceLabel.styleType === 'lowerPrice') {
    goodsInfo.pcStandardView.historicalPrice = [resData]
    goodsInfo.pcBroadwiseView.historicalPrice = [resData]

    return {
      historicalPrice: resData
    }
  } else if (bffHisLowPriceLabel.styleType === 'followPrice') {
    goodsInfo.pcStandardView.followPrice = resData
    goodsInfo.pcBroadwiseView.followPrice = resData

    return {
      followPrice: resData
    }
  }
}

// MARK: 主图角标构造 start
const BffkeyToWebKey = {
  // upperLeftFlexibleLabel: 'upperLeftFlexibleLabel',
  upperRightFlexibleLabel: 'upperRightFlexibleLabel',
  upperLeftPositionInfo: 'UPPER_LEFT_LABEL',  // 左上
  upperRightPositionInfo: 'UPPER_RIGHT_LABEL',  // 右上
  lowerLeftPositionInfo: 'LOWER_LEFT_LABEL',  // 左下
  lowerRightPositionInfo: 'LOWER_RIGHT_LABEL' // 右下
}

/**
 * 色块数据
 * @param {*} bffGoodsInfo 
 * @param {*} bffDirectionData 
 * @returns 
 */
const createColorInformationLocateLabels = (bffGoodsInfo, bffDirectionData) => {
  const relatedColor = bffGoodsInfo?.relatedColorNew?.map(item => {
    return {
      goods_color_image: item.colorImage,
      goods_id: item.goods_id
    }
  })
  return {
    relatedColor,
    materialValueKey: bffDirectionData.contentType,
    appTraceInfo: bffDirectionData.appTraceInfo,
    relatedColorCount: bffGoodsInfo?.relatedColorNew?.length
  }
}

/**
 * 品牌系列角标数据
 * @param {*} bffDirectionData 
 * @returns 
 */
const createBrandOrSeriesLocateLabels = (bffDirectionData) => {
  return {
    corner_img_left: bffDirectionData.image,
    corner_img_right: bffDirectionData.image,
    materialValueKey: bffDirectionData.displayType,
    appTraceInfo: bffDirectionData.appTraceInfo
  }
}

/**
 * 文字角标数据
 * @param {*} bffDirectionData 
 * @returns 
 */
const createTextAndImgLocateLabels = (bffDirectionData) => {
  
  let cornerPropertyUpsell = null
  if (bffDirectionData.contentType === 'lower_right_attr_labels') {
    cornerPropertyUpsell = {
      isShowColorBlock: 'noShow',
      appTraceInfo: bffDirectionData.appTraceInfo,
      icon: bffDirectionData.image || '',
      type: bffDirectionData.displayType,
      textList: [{
        labelLang: bffDirectionData.labelLang
      }]
    }
  }

  if (cornerPropertyUpsell) {
    return {
      cornerPropertyUpsell,
      appTraceInfo: cornerPropertyUpsell.appTraceInfo
    }
  } else {
    return {
      icon: bffDirectionData.image,
      fontColor: bffDirectionData.fontColor,
      backgroundColor: bffDirectionData.backgroundColor,
      borderColor: bffDirectionData.borderColor,
      labelLang: bffDirectionData.labelLang,
      subscriptType: bffDirectionData.displayType,
      materialValueKey: bffDirectionData.contentType,
      appTraceInfo: bffDirectionData.appTraceInfo
    }
  }
}

// 根据角标type不同 分别处理
const createLocateLabelschildData = (bffGoodsInfo, bffDirectionData) => {
  const { contentType } = bffDirectionData
  if (contentType === 'color_information') {
    return createColorInformationLocateLabels(bffGoodsInfo, bffDirectionData)
  } else if (contentType === 'subscript') {
    return createBrandOrSeriesLocateLabels(bffDirectionData)
  } else {
    return createTextAndImgLocateLabels(bffDirectionData)
  }
}

// 各个方向的数据 处理
const createDirectionLocateLabels = (bffGoodsInfo, key) => {
  const directionData = {}

  const productMaterial = bffGoodsInfo.productMaterial
  Object.keys(BffkeyToWebKey).forEach(bffKey => {

    // 取对应角标数据
    const bffDirectionData = productMaterial?.[bffKey]?.[key]
    if (bffDirectionData) {
      const key = BffkeyToWebKey[bffKey]
      directionData[key] = createLocateLabelschildData(bffGoodsInfo, bffDirectionData)
    }
  })

  return directionData
}
export const createLocateLabels = (bffGoodsInfo, options) => {
  // 真对实时反馈弹窗里的商品就不需要展示这些角标，因为商卡没有相关配置不展示，所以在数据源给掐掉
  if(options?.noLocateLabels) {
    return {
      pcStandardView: {},
      pcBroadwiseView: {}
    }
  }
  return {
    pcStandardView: createDirectionLocateLabels(bffGoodsInfo, 'twoColumnStyle'),
    pcBroadwiseView: createDirectionLocateLabels(bffGoodsInfo, 'oneColumnStyle')
  }
}
// MARK: 主图角标构造 end


// MARK: 价格相关 start

// 到手价 
// warning: 新客到手价 java中间层没有返回标识
const createEstimatedPrice = (bffGoodsInfo, options) => {
  // 中间层内部逻辑自己判断了, 非付费会员且非付费商品是不会返回原价到手价的, 
  // 如果判断promotionInfo有包含了29活动且estimatedPriceInfo非空, 那他就是付费会员到手价
  const isPaid = options?.isPaid ?? false
  const lang = options?.lang ?? ''

  const bffEstimatedPriceInfo = bffGoodsInfo.estimatedPriceInfo

  const estimatedDiscount = bffEstimatedPriceInfo.estimatedDiscount

  const estimatedPriceInfo = {
    satisfied: bffEstimatedPriceInfo.isSatisfied,
    estimatedPrice: bffEstimatedPriceInfo.estimatedPrice,
    estimatedDiscountPrice: bffEstimatedPriceInfo.total_discount_value,
    estimatedDiscount: estimatedDiscount > 0 ? `${lang !== 'il' ? '-' : ''}${estimatedDiscount}%` : '',
    estimatedLang: bffEstimatedPriceInfo.estimatedMultiLang,
    estimatedPriceType: 3,
    endTime: bffEstimatedPriceInfo.endTime ?? ''
  }

  // 付费会员到手价
  const sheinClubEstimatedPrice = bffGoodsInfo?.promotionInfo?.find( v => +v.typeId === 29)
  if (isPaid && sheinClubEstimatedPrice) {
    estimatedPriceInfo.estimatedPriceType = 2
  }

  // s3会员到手价
  const exclusiveEstimatedPrice = bffGoodsInfo?.promotionInfo?.find( v => +v.typeId === 12)
  if (exclusiveEstimatedPrice) {
    estimatedPriceInfo.estimatedPriceType = 4
  }

  return estimatedPriceInfo
}

// 付费会员价  s3会员的数据
const createVipPrice = (promotionInfo, options) => {
  const lang = options?.lang ?? ''

  const retailDiscountPercent = promotionInfo.retailDiscountPercent
  const aggregateMemberResult = promotionInfo.aggregateMemberResult

  const { discountAmount, convertDiscountValue, discountPercentFromRetailPrice } = aggregateMemberResult
  return {
    price: promotionInfo.price,
    unitPrice: promotionInfo.unitPrice,
    isInversion: promotionInfo.isInversion,
    discount_amount: discountAmount,
    discountValue: convertDiscountValue,
    discountPercent: discountPercentFromRetailPrice,

    retail_discount_percent: retailDiscountPercent,
    retail_discount_price: promotionInfo.retailDiscountPrice,
    suggestedSalePrice: promotionInfo.suggestedSalePrice,

    srpDiscount: promotionInfo.srpDiscount,
    srpDiscountPrice: promotionInfo.srpDiscountPrice,
    retailDiscountPercentString: retailDiscountPercent > 0 ? `${lang !== 'il' ? '-' : ''}${retailDiscountPercent}%` : '',
    discountPercentString: discountPercentFromRetailPrice > 0 ? `${lang !== 'il' ? '-' : ''}${discountPercentFromRetailPrice}%` : '',
    srpDiscountPercentString: promotionInfo?.srpDiscount > 0 ? `${lang !== 'il' ? '-' : ''}${promotionInfo.srpDiscount}%` : ''
  }
}

// 付费会员价
const createSheinClubPrice = (bffGoodsInfo, promotionInfo, options) => {

  const sheinClubPromotionInfo = createVipPrice(promotionInfo, options)

  return sheinClubPromotionInfo
}

// s3会员价
const createExclusivePrice = (bffGoodsInfo, promotionInfo, options) => {

  const exclusivePromotionInfo = createVipPrice(promotionInfo, options)

  return exclusivePromotionInfo
}

// 闪购
// 分人群的闪购
const createFlashZonePromotion = (bffGoodsInfo, promotionInfo, options) => {
  const lang = options?.lang ?? ''


  const { discountAmount = {}, convertDiscountValue = 0, discountPercentFromRetailPrice = 0 } = promotionInfo.aggregatePromotionBusiness
  return {
    isInversion: promotionInfo.isInversion,
    price: promotionInfo?.price,
    unitPrice: promotionInfo.unitPrice,
    typeId: promotionInfo.typeId,
    flash_type: +promotionInfo.flash_type,

    discount_amount: discountAmount,
    discountValue: convertDiscountValue,
    discountPercent: discountPercentFromRetailPrice,
    discount_percent: discountPercentFromRetailPrice,

    retail_discount_percent: promotionInfo.retailDiscountPercent,
    retail_discount_price: promotionInfo.retailDiscountPrice,
    suggestedSalePrice: promotionInfo.suggestedSalePrice,

    srpDiscount: promotionInfo.srpDiscount,
    srpDiscountPrice: promotionInfo.srpDiscountPrice,
    srpDiscountPercentString: promotionInfo.srpDiscount > 0 ? `${lang !== 'il' ? '-' : ''}${promotionInfo.srpDiscount}%` : '',
    discountPercentString: convertDiscountValue > 0 ? `${lang !== 'il' ? '-' : ''}${convertDiscountValue}%` : '',
    retailDiscountPercentString: promotionInfo.retailDiscountPercent > 0 ? `${lang !== 'il' ? '-' : ''}${promotionInfo.retailDiscountPercent}%` : ''
  }
}

// 新型闪购
const createNewFlashPromotion = (bffGoodsInfo, promotionInfo, options) => {
  const language = options.language
  const lang = options?.lang ?? ''

  const flashTypeTextMap = new Map([
    [2, language.SHEIN_KEY_PWA_21928],
    [3, language.SHEIN_KEY_PWA_21932]
  ])

  const { discountAmount = {}, convertDiscountValue = 0, discountPercentFromRetailPrice = 0 } = promotionInfo.aggregatePromotionBusiness

  return {
    isInversion: promotionInfo.isInversion,
    price: promotionInfo.price,
    unitPrice: promotionInfo.unitPrice,
    flash_type: +promotionInfo.flash_type,
    typeId: promotionInfo.typeId,
    flashTypeText: flashTypeTextMap.get(+promotionInfo.flash_type) || '',

    discount_amount: discountAmount,
    discountValue: convertDiscountValue,
    discountPercent: discountPercentFromRetailPrice,
    discount_percent: discountPercentFromRetailPrice,

    retail_discount_percent: promotionInfo.retailDiscountPercent,
    retail_discount_price: promotionInfo.retailDiscountPrice,
    suggestedSalePrice: promotionInfo.suggestedSalePrice,

    srpDiscount: promotionInfo.srpDiscount,
    srpDiscountPrice: promotionInfo.srpDiscountPrice,
    srpDiscountPercentString: promotionInfo.srpDiscount > 0 ? `${lang !== 'il' ? '-' : ''}${promotionInfo.srpDiscount}%` : '',
    discountPercentString: convertDiscountValue > 0 ? `${lang !== 'il' ? '-' : ''}${convertDiscountValue}%` : '',
    retailDiscountPercentString: promotionInfo.retailDiscountPercent > 0 ? `${lang !== 'il' ? '-' : ''}${promotionInfo.retailDiscountPercent}%` : ''

  }
}

/**
 * 
 * @param {*} bffGoodsInfo 
 * @param {*} options 
 * @returns {
 *  estimatedPriceInfo
 *  sheinClubPromotionInfo
 *  flashZonePromotion
 *  newFlashPromotion
 * }
 */
export const createPriceRelatedData = (bffGoodsInfo, options) => {
  const lang = options?.lang ?? ''

  const priceData = {} 

  // 售价折扣
  if (bffGoodsInfo.unit_discount) {
    priceData.unitDiscountString = bffGoodsInfo.unit_discount > 0 ? `${lang !== 'il' ? '-' : ''}${bffGoodsInfo.unit_discount}%` : ''
  }

  // 到手价
  if (bffGoodsInfo.estimatedPriceInfo?.isSatisfied) {
    priceData.estimatedPriceInfo = createEstimatedPrice(bffGoodsInfo, options)
  }
  
  // 合规站点 建议零售价 RRP价
  if (bffGoodsInfo.srpPriceInfo?.srpPrice?.amount > 0) {
    const srpDiscount = bffGoodsInfo.srpDiscount
    priceData.srpDiscountPercentString = srpDiscount > 0 ? `${lang !== 'il' ? '-' : ''}${srpDiscount}%` : ''
  }

  const promotionInfo = bffGoodsInfo.promotionInfo
  if (!promotionInfo) return priceData

  // 付费会员价 promotionInfo 数组存在 typeId 为 29 的元素
  const promotionInfoSheinClubPrice = promotionInfo.find( v => +v.typeId === 29)

  // promotionInfo 数组存在 typeId 为 10 的元素
  const promotionInfoFlashPrice = promotionInfo.find( v => +v.typeId === 10)

  // 付费会员价 promotionInfo 数组存在 typeId 为 29 的元素
  const promotionInfoExclusivePrice = promotionInfo.find( v => +v.typeId === 12)


  // 付费会员价
  if (promotionInfoSheinClubPrice && promotionInfoSheinClubPrice?.price?.amount) {
    priceData.sheinClubPromotionInfo = createSheinClubPrice(bffGoodsInfo, promotionInfoSheinClubPrice, options)
  }

  // s3会员价
  if (promotionInfoExclusivePrice && promotionInfoExclusivePrice?.price?.amount) {
    priceData.exclusivePromotionInfo = createExclusivePrice(bffGoodsInfo, promotionInfoExclusivePrice, options)
    priceData.exclusivePromotionPrice = priceData.exclusivePromotionInfo.price
  }


  //  闪购
  if (promotionInfoFlashPrice) {
    const hasPrice = promotionInfoFlashPrice?.price?.amount

    if (+promotionInfoFlashPrice.flash_type === 4 && hasPrice) {
      // 分人群闪购
      priceData.flashZonePromotion = createFlashZonePromotion(bffGoodsInfo, promotionInfoFlashPrice, options)
    } else if ([2, 3].includes(+promotionInfoFlashPrice.flash_type) && hasPrice) {
      // 普通闪购
      priceData.newFlashPromotion = createNewFlashPromotion(bffGoodsInfo, promotionInfoFlashPrice, options)
    }

    // 常规、直播闪购
    if (![2, 3].includes(+promotionInfoFlashPrice.flash_type)) {
      priceData.flashsale = 1
    }
  }

  return priceData
}

// 折扣标签
export const createDiscountInfo = (bffGoodsInfo, goodsInfo, options) => {
  const lang = options?.lang ?? ''

  const suggestedSalePriceType = bffGoodsInfo.suggestedSaleType || goodsInfo?.suggestedSaleType || ''

  const { newFlashPromotion } = goodsInfo.pretreatInfo

  const { unit_discount, retailDiscountPercent, retailDiscountPrice } = bffGoodsInfo

  const unitDiscount = unit_discount 
  const retailDiscountPercentValue = retailDiscountPercent
  
  const notUnitDiscount = (!suggestedSalePriceType && (!unitDiscount || Number(unitDiscount) === 0))

  if (notUnitDiscount && !newFlashPromotion) return false

  const newFlashDiscount = newFlashPromotion?.discount_percent

  const newFlashRetailDiscount = newFlashPromotion?.retail_discount_percent 

  const discount = newFlashDiscount || unitDiscount // 新型闪购折扣率优先于原折扣率
  const retailDiscount = newFlashRetailDiscount || retailDiscountPercentValue
  const unitDiscountString = discount > 0 ? `${lang !== 'il' ? '-' : ''}${discount}%` : ''

  const discountInfo = {
    show: !!unitDiscountString,
    suggestedSalePriceType: suggestedSalePriceType,
    unitDiscountString: unitDiscountString,
    originDiscountRate: unit_discount / 100,
    newFlashDiscount,
    retailDiscountPrice,
    retailDiscountPercent: retailDiscountPercentValue,
    retailDiscountPercentString: retailDiscount > 0 ? `${lang !== 'il' ? '-' : ''}${retailDiscount}%` : '',
  }

  return discountInfo
}

// 合规价
export const createSuggestedSalePriceConfigInfo = (bffGoodsInfo, options) => {
  const suggestedSalePriceType = bffGoodsInfo.suggestedSaleType || options?.suggestedSaleType || ''

  if (!suggestedSalePriceType) return null
  const hideSuggestedSalePrice = options?.hideSuggestedSalePrice
  const language = options?.language

  let lowestPriceLabelLang = ''
  let pricePopoverLang = ''
  if (['special', 'special_de'].includes(suggestedSalePriceType)) {
    lowestPriceLabelLang =  language.SHEIN_KEY_PC_30516 || 'lowest price' // 多语言替换
    pricePopoverLang = language.SHEIN_KEY_PC_31050 || 'This is the original price which is also the lowest price in the 30 days prior to the reduction' // 多语言替换
  }

  const suggestedSalePriceConfigInfo = {
    pricePopoverLang,
    lowestPriceLabelLang,
    type: suggestedSalePriceType,
    config: {
      hideDiscount: suggestedSalePriceType !== 'special_de',
      hideSuggestedSalePrice: !!hideSuggestedSalePrice,
      hideTargetBySuggestedSalePrice: true, // 所有跟折扣有关的标签（折扣标签，到手价文字描述，老划线价，付费会员折扣标）
      cartHideEstimatedPrice: true // 给加车弹窗使用，不展示到手价
    }
  }

  return suggestedSalePriceConfigInfo
}

// MARK: 价格相关 end



// MARK: 主图腰带 start
const createBeltLabelData = (bffGoodsInfo, goodsInfo, key) => {

  const bffPictureBelt = bffGoodsInfo.productMaterial.pictureBelt?.[key]

  if (!bffPictureBelt) return null

  const estimatedLabelLang = bffPictureBelt.labelLang || ''

  let beltLabelData = {
    materialValueKey: bffPictureBelt.materialValueKey,
    ana: bffPictureBelt.appTraceInfo,
    component: bffPictureBelt.component,
    bgImage: bffPictureBelt.bgImage,
    shapedImage: bffPictureBelt.shapedImage,
    bigCardBgImageSize: bffPictureBelt.bigCardBgImageSize,
    bigCardBgImage: bffPictureBelt.bigCardBgImage,
    transparentImage: bffPictureBelt.transparentImage,
    bigCardTransparentImage: bffPictureBelt.bigCardTransparentImage,
    fontColor: bffPictureBelt.fontColor,
    saveFontColor: bffPictureBelt.saveFontColor,
    labelLang: bffPictureBelt.labelLang,
    saveLabelLang: bffPictureBelt.saveLabelLang,
    bgImageSize: bffPictureBelt.bgImageSize,
    shapedImageSize: bffPictureBelt.shapedImageSize,
    savePrice: bffPictureBelt.discountPrice?.amountWithSymbol ?? '',
    icon: bffPictureBelt.icon,
    currentTime: Date.now(),
    endTime: bffPictureBelt.endTime ? (bffPictureBelt.endTime * 1000) : 0,
    estimatedLabelLang: estimatedLabelLang
  }

  // 闪购腰带处理
  if (bffPictureBelt.materialValueKey === 'flash_purchase_belt') {
    const promotionInfo = bffGoodsInfo.promotionInfo

    const promotionFlashSale = promotionInfo.find(item => +item.typeId === 10)
    
    // 促销接口是否可以命中
    if (promotionFlashSale && promotionFlashSale?.endTimestamp && Number(promotionFlashSale?.endTimestamp) * 1000 > beltLabelData.currentTime) {
      const { estimatedDiscountPrice } = goodsInfo?.estimatedPriceInfo || {}
      const { discountPrice, retailDiscountPrice } = goodsInfo || {}
      // 减免金额(存在到手价的话就会取到手价的差价，没有的话取特价的差价)
      const savedPrice = estimatedDiscountPrice || discountPrice

      // 4 专区闪购
      const IsFlashZonePromotion = !!goodsInfo.flashZonePromotion?.price?.amountWithSymbol
      
      // 活动id=10（过滤2、3类型闪购不展示列表普通闪购腰带的样式，其他类型的闪购都正常展示闪购腰带）
      if (promotionFlashSale?.flash_type == 2 || promotionFlashSale?.flash_type == 3) return 

      if (promotionFlashSale?.flash_type == 4 && !IsFlashZonePromotion) return

      beltLabelData = {
        ana: bffPictureBelt.appTraceInfo,
        endTime: Number(promotionFlashSale?.endTimestamp) * 1000,
        savedPrice: savedPrice,
        savedPriceText: savedPrice?.amountWithSymbol || '',
        retailDiscountPrice: retailDiscountPrice,
        component: 'flash_purchase_belt'
      }
    }
  } else if (bffPictureBelt.component === 'firstBuyVoucher') {
    const { labelLang, emphasizedText, appTraceInfo } = bffPictureBelt
    const textList = labelLang?.split(emphasizedText)

    let offText = ''
    let discountValue = ''
    let symbol = ''

    if (labelLang?.includes(emphasizedText)) {
      discountValue = emphasizedText
      offText = labelLang.replace(emphasizedText, '')
      
      if (labelLang?.indexOf(emphasizedText) !== 0) {
        symbol = textList?.[0] || ''
      }
    } else {
      offText = labelLang
    }

    // 首金腰带
    beltLabelData = {
      offText,
      symbol,
      discountValue,
      isVoucherBelt: true,
      ana: appTraceInfo
    }
  }

  return beltLabelData
}

export const createBeltLabel = (bffGoodsInfo, goodsInfo, options) => {
  return {
    pcStandardView: createBeltLabelData(bffGoodsInfo, goodsInfo, 'twoColumnStyle', options),
    pcBroadwiseView: createBeltLabelData(bffGoodsInfo, goodsInfo, 'oneColumnStyle', options),
  }
}

// MARK: 主图腰带 end


// MARK: 榜单轮播相关 start
const createRankListRowData = (bffGoodsInfo, key) => {
  const bffRankData = bffGoodsInfo.rankInfo?.[key] ?? {}

  if (!bffRankData?.carrierId) {
    return null
  }

  return {
    rankingType: bffRankData.materialValueKey,
    carrierSubType: bffRankData.carrierSubType,
    composeIdText: bffRankData.composeIdText,
    rankTypeText: bffRankData.rankTypeText,
    icon: bffRankData.icon,
    backgroundColor: bffRankData.bgColor,
    fontColor: bffRankData.tagColor,
    contentCarrierId: bffRankData.carrierId,
    title: bffRankData.title,
    ana: bffRankData.appTraceInfo,
    cateIds: bffRankData.cateId,
    boardGenerateType: bffRankData.boardGenerateType,
    srcIdentifier: bffRankData.srcIdentifier,
    carrierSubTypeName: bffRankData.carrierSubTypeName,
    carrierType: bffRankData.carrierType,
    carrierTypeName: bffRankData.carrierTypeName,
    infoFlow: bffRankData.infoFlow,
    score: bffRankData.score || ''
  }
}

export const createRankList = (bffGoodsInfo) => {
  return {
    pcStandardView: createRankListRowData(bffGoodsInfo, 'twoColumnStyle'),
    pcBroadwiseView: createRankListRowData(bffGoodsInfo, 'oneColumnStyle')
  }
}

// MARK: 榜单轮播相关 end

// MARK: 卖点轮播相关 start
// 星级评论
const createStarComment = (bffGoodsInfo, customData = {}) => {
  const comment_rank_average = Number(bffGoodsInfo.comment_rank_average)
  if (isNaN(comment_rank_average) || comment_rank_average === 0) {
    return null
  }

  let star_html = new Array(parseInt(comment_rank_average)).fill('<i class="sh_pc_sui_icon sh_pc_sui_icon_star_5_16px_1_honor"></i>')
  // 插入一个半星 全星 3.5 4.5的情况
  const decimal = Number(parseFloat(comment_rank_average).toFixed(2).split('.')[1]) || 0
  if (decimal > 0) {
    if (decimal <= 25) {
      star_html.push('<i class="sh_pc_sui_icon sh_pc_sui_icon_star_2_16px_1_honor"></i>')
    } else if (decimal <= 50) {
      star_html.push('<i class="sh_pc_sui_icon sh_pc_sui_icon_star_3_16px_1_honor"></i>')
    } else if (decimal <= 99) {
      star_html.push('<i class="sh_pc_sui_icon sh_pc_sui_icon_star_4_16px_1_honor"></i>')
    }
  }
  // 如果不满五个，需要补齐
  if (star_html.length < 5) {
    const transparentStart = new Array(5 - star_html.length).fill('<i class="sh_pc_sui_icon sh_pc_sui_icon_star_1_16px_1_honor"></i>')

    star_html.push(...transparentStart)
  }

  const startCommentData = {
    star_html: star_html.join(''),
    comment_num: bffGoodsInfo.comment_num,
    comment_num_str: bffGoodsInfo.comment_num_show,
    comment_rank_average: bffGoodsInfo.comment_rank_average
  }

  return Object.assign(startCommentData, customData)
}

// 非赛马轮播
const createSellingPointUniversalLabelsData = (bffGoodsInfo, options) => {
  const rowKey = options?.rowKey || 'TWO_IN_A_ROW'

  const getQuickship = () => {
    const quickshipData = bffGoodsInfo.productInfoLabels.quickShipLabel
    return {
      quickship: {
        suiIcon: 'sh_pc_sui_icon_qucikship_flat_{0}_198055',
        fontColor: '#198055', 
        labelLang: quickshipData.labelLang,
        labelCustomType: 'quickship'
      }
    }
  }
  const productAttributeLabelList = bffGoodsInfo.productMaterial?.productAttributeLabelList ?? []

  const sellingPointUniversalLabels = productAttributeLabelList.map(item => {
    switch (item.contentType) {
      // 星级评论
      case 'number_of_comments': {
        const starComment = createStarComment(bffGoodsInfo, {
          tagId: item.tagId
        })

        if (starComment) {
          return {
            materialValueKey: 'number_of_comments',
            starComment
          }
        } else {
          return null
        }
      }
      // 用户行为标签
      case 'custom': 
        return {
          materialValueKey: 'custom',
          userBehaviorLabel: {
            appTraceInfo: item.behaviorLabel.appTraceInfo,
            labelLang: item.behaviorLabel.tagName,
            fontColor: item.behaviorLabel.tagColor,
            backgroundColor: item.behaviorLabel.bgColor,
            labelId: item.behaviorLabel.tagId,
            icon: item.behaviorLabel.icon
          }
        }
      // 用户评论关键词
      case 'user_comment':
        return {
          userCommentKeywordsLabel: {
            appTraceInfo: item.userComments.appTraceInfo,
            labelLang: item.userComments.tagName,
          }
        }
      // 人工卖点
      case 'artificial_selling_point':
        return {
          sellingPoints: bffGoodsInfo.sellingPoint
        }
      // 决策属性
      case 'decision_attributes':
        return {
          decisionAttrList: bffGoodsInfo.featureSubscript.filter(v => {
            return v.feature_type === 'show_attr' && v.feature_show_type === rowKey
          }).map(item => {
            return {
              feature_show_type: item.feature_show_type,
              attrId: item.feature_id,
              name: item.feature_name,
              'name-en': item.feature_name_en
            }
          })
        }
      // 快速发货
      case 'quick_ship':
        return getQuickship()
    }
  })

  return sellingPointUniversalLabels.filter(v => v)
}

export const createSellingPointUniversalLabels = (bffGoodsInfo) => {
  return {
    pcStandardView: createSellingPointUniversalLabelsData(bffGoodsInfo, { rowKey: 'TWO_IN_A_ROW' }),
    pcBroadwiseView: createSellingPointUniversalLabelsData(bffGoodsInfo, { rowKey: 'ONE_IN_A_ROW' }),
  }
}

// 赛马轮播
export const createHorseRaceLabelData = (bffGoodsInfo) => {
  const v2ProductAttributeLabelList = bffGoodsInfo.productMaterial?.v2ProductAttributeLabelList ?? []

  const horseRaceLabel = v2ProductAttributeLabelList.map(item => {
    switch (item.contentType) {
      case 'number_of_comments': {
        const starComment = createStarComment(bffGoodsInfo, {
          appTraceInfo: item.appTraceInfo,
          tagId: item.tagId,
        })

        if (starComment) {
          return {
            starComment
          }
        } else {
          return null
        }
      }
      
      case 'quick_ship':
        return {
          userBehaviorLabel: {
            appTraceInfo: item.appTraceInfo,
            labelLang: item.labelLang,
            italicIcon: true, 
            fontColor: '#198055', 
            suiIcon: 'sh_pc_sui_icon_qucikship_flat_{0}_198055',
            labelCustomType: 'quickship'
          }
        }

      case 'custom':
        return {
          userBehaviorLabel: {
            labelLang: item.labelLang,
            appTraceInfo: item.appTraceInfo,
            fontColor: item.fontColor,
            backgroundColor: item.backgroundColor,
            labelId: item.tagId,
            icon: item.icon
          }
        }
      
      case 'ranking':
        return {
          rankingList: {
            isTypeMatch: true,
            tagId: item.tagId
          }
        }
    }
  })

  return horseRaceLabel.filter(v => v)
}

// MARK: 卖点轮播相关 end



// MARK: 服务标签相关 start

export const createServiceLabelsData = (bffGoodsInfo, options = {
  mixPromotionInfo: []
}) => {
  const productServiceLabelList = bffGoodsInfo.productMaterial?.productServiceLabelList ?? []
  const mixPromotionInfo = options.mixPromotionInfo || []

  const getCustomLabel = (productServiceLabelListItem) => {
    return {
      contentType: 'custom',
      appTraceInfo: productServiceLabelListItem.appTraceInfo,
      fontColor: productServiceLabelListItem.fontColor,
      backgroundColor: productServiceLabelListItem.backgroundColor,
      icon: productServiceLabelListItem.icon,
      labelId: productServiceLabelListItem.tagId,
      labelLang: productServiceLabelListItem.labelLang,
    }
  }

  const getQuickship = (productServiceLabelListItem) => {
    const quickshipData = bffGoodsInfo.productInfoLabels.quickShipLabel
    
    const newStyle = productServiceLabelListItem.displayStyle === 'strengthen'

    return {
      quickship: {
        appTraceInfo: productServiceLabelListItem.appTraceInfo,
        labelLang: quickshipData.tag_val_name_lang,
        italicText: !!newStyle,
        newStyle: !!newStyle,
        suiIcon: newStyle ? 'sh_pc_sui_icon_qucikship_flat_{0}_198055' : ''
      }
    }
  }

  const getLocalMall = () => {
    const localDelivery = bffGoodsInfo.productInfoLabels.localDelivery

    const appTraceInfo = `show_service_label_local_shipping_${localDelivery.tag_val_name_lang?.toLocaleLowerCase()}`
    return {
      localMall: {
        appTraceInfo: appTraceInfo.replace(/\x20/g, '_'),
        labelLang: localDelivery.tag_val_name_lang,
      }
    }
  }

  const customLabelList = []

  // 需要把通用角标整合成数组再塞进去
  const serviceLabels = productServiceLabelList.map((item) => {

    switch (item.contentType) {
      case 'custom':
      // ccc角标
        customLabelList.push(getCustomLabel(item))
        return false
      case 'quick_ship':
      // 快速发货
        return getQuickship(item)
      case 'local_shipping':
      // 本地发货
        return getLocalMall(item)
      case 'promotion_label':
        return {
          promotionLabel: mixPromotionInfo?.[0]?.lable
          // 前端预处理转
          // promotionLabel: 1
        }
      case 'quality_shop_label':
        // 优秀店铺卖点信息
        return {
          qualityShopLabel: {
            contentType: 'quality_shop_label',
            labelLang: item.labelLang,
            tagId: item.tagId,
            appTraceInfo: item.appTraceInfo,
            suiIcon: 'sh_pc_sui_icon_quality_shop_flat_14px'
          }
        }
    }
  }).filter(v => !!v)

  if (customLabelList.length > 0) {
    serviceLabels.push({
      universalLabel: customLabelList
    })
  }

  return serviceLabels
}

// MARK: 服务标签相关 end

export const createStoreInfo = (bffGoodsInfo) => {
  const storeInfo = bffGoodsInfo.storeInfo
  return {
    storeCode: bffGoodsInfo?.store_code || '',
    title: storeInfo.storeName,
    storeBusinessType: 2
  }
}

// MARK: 趋势词 时尚店铺 start
export const createTrendLabel = (bffGoodsInfo) => {
  const trendLabel = bffGoodsInfo.productMaterial?.trendLabel

  // {
  //   sales
  //   isFollow
  //   storeCode
  //   title
  //   isNewStyleTag
  //   isModStore
  // }

  // 时尚店铺 & 品质店铺
  if (['qualityStore', 'fashionStore'].includes(trendLabel.trendType)) {
    const isChoiceStore = trendLabel?.trendType === 'qualityStore'
    const isModStore = trendLabel?.trendType === 'fashionStore'

    return {
      storeInfo: {
        attributeLabelList: trendLabel.attributeLabelList ?? [],
        isModStore: isModStore,
        isChoiceStore: isChoiceStore,
        appTraceInfo: trendLabel.appTraceInfo,
        storeCode: trendLabel.trendShopCode,
        title: trendLabel.trendLabel.labelName,
        logo: trendLabel.fashionStoreLogo,
        jumpHomeTab: trendLabel.trendStoreTabAbtValue === 'homeTab'
      },
      deliveryInfo: {
        trendType: trendLabel.trendType,
        img: trendLabel.trendIpImg?.imgUrl,
      }
    }
  } else if (trendLabel.trendType === 'trend') {
    return {
      deliveryInfo: {
        trendType: trendLabel.trendType,
        appTraceInfo: trendLabel.appTraceInfo,
        img: trendLabel.trendIpImg?.imgUrl,
        display_title: trendLabel.trendLabel?.labelName,
        product_select_id: trendLabel.productSelectId,
        product_select_url_id: trendLabel.productSelectUrlId,
        trend_word_id: trendLabel.trendWordId
      }
    }
  } else if (trendLabel.trendType === 'qualityWord') {
    return {
      deliveryInfo: {
        trendType: trendLabel.trendType,
        img: trendLabel.trendIpImg?.imgUrl,
        imgWidth: trendLabel.trendIpImg?.width,
        ...trendLabel.sellPointWordInfo
      }
    }
  }
}

// 主图蒙层
export const createMaskLayer = (bffGoodsInfo) => {
  const bffMaskLayer = bffGoodsInfo.productMaterial?.maskLayer ?? {}

  return {
    maskLayerSrc: bffMaskLayer.image,
    maskLayerWidth: bffMaskLayer.width,
    maskLayerHeight: bffMaskLayer.height,
    appTraceInfo: bffMaskLayer.appTraceInfo,
  }
}

// 收藏 售罄 库存
export const createSizeStock  = (bffGoodsInfo, options) => {
  const language = options?.language

  const skuData = bffGoodsInfo.skuInventoryList?.find((i) => i.sku_code == bffGoodsInfo.sku_code)
  const mallInventory = skuData?.mall_inventory_list?.find((i) => i.mall_code == bffGoodsInfo.mall_code)
  const sizeStock = mallInventory?.inventory // 库存数

  const _stock = bffGoodsInfo.sku_code || +bffGoodsInfo.originalAttrValueId ? sizeStock : bffGoodsInfo.stock
  let stockLeft = +_stock > 0 && +_stock < 10 ? template(_stock, language.SHEIN_KEY_PC_16400) : ''

  return {
    sizeStock: sizeStock || '',
    stockLeft: stockLeft,
    stockLeftReady: true
  }
}

// 收藏主图右下角 销售属性
export const createSaleAttr = (bffGoodsInfo) => {
  const skuAttrStr = bffGoodsInfo.skuAttrStr
  return {
    label: skuAttrStr,
    analysis: skuAttrStr ? `attribute_show_${skuAttrStr}` : 'attribute_null'
  }
}

// 降价标识
export const createReducePrice = (bffGoodsInfo, options) => {
  const language = options?.language
  const reducePrice = bffGoodsInfo.reducePrice

  return {
    label: template(reducePrice.amountWithSymbol, (language.SHEIN_KEY_PC_16553 || '{0} less than when added')),
    usdAmount: reducePrice.usdAmount
  }
}

// 分布式筛选
export const createSearchFilters = (bffGoodsInfo) => {
  const attrs = bffGoodsInfo.attrs

  return {
    searchFilters: attrs.map(item => {
      return {
        ...item,
        id: `${item.attr_id}_${item.attr_value_id}`,
        filterId: item.attr_value_id,
        filterWord: item.attr_values,
        dataSource: item.attr_type
      }
    })
  }
}

// 客户端预处理中的促销标签字段 相关处理（参见public/src/services/goodsItemInfo/goodsPretreatInfo.js 中的setPromotionLabel）
export const createMixPromotionInfo = (bffGoodsInfo) => {
  // result value info

  let mixPromotionInfo = bffGoodsInfo?.promotionInfo?.filter(item => {
    if (item.typeId) {
      // ab价不展示服务类型标签
      if (+item.typeId === 32) {
        return false
      } else if (+item.typeId === 31) { // 31品类限定商家限时直降 wiki.pageId=1198803653
        return +item.promotion_logo_type === 3
      } else if ([3, 10, 11, 29].includes(+item.typeId)) { // 3: 限时折扣 10: 闪购 11: 直播闪购 29: 促销
        return true
      } else if (item.lable) {
        return true
      }
    }
    return false
  })?.map((item) => {
    // 促销icon
    Object.entries(IconPromotionTypeIds).some(([icon, promotionTypeIds]) => {
      if (promotionTypeIds.includes(+item.typeId)) {
        item.icon = icon
        return true
      }
    })

    item.typeId = +item.typeId
    if (item.promotion_logo_type) {
      item.promotion_logo_type = +item.promotion_logo_type
    }

    return item
  }) || []

  return {
    mixPromotionInfo, 
    promotionReady: true,
  }
}

// 埋点字段
export const createExt = (bffGoodsInfo) => {
  const ext = bffGoodsInfo.ext
  const dynamic_ext = bffGoodsInfo.dynamic_ext

  let ext_other_ext_mark = ''
  if (ext) {
    ext_other_ext_mark = Object.keys(ext)
      .reduce((curr, next) => {
        if (next != 'rec_mark' && next != 'extra_mark') {
          curr.push(`${next}_${ext[next]}`)
        }
        return curr
      }, [])
      .join('|')
  }

  let dynamic_ext_other_ext_mark = ''
  if (dynamic_ext) {
    dynamic_ext_other_ext_mark = Object.keys(dynamic_ext)
      .map(_ => `${_}_${dynamic_ext[_]}`)
      .join('|')
  }

  return {
    ext_other_ext_mark,
    dynamic_ext_other_ext_mark
  }
}

// 收藏人数
export const createQuantity = (bffGoodsInfo, options) => {
  const language = options?.language

  let count = bffGoodsInfo.count || 1


  if (count && count > 999) {
    count = Math.floor((count / 1000) * 10) / 10 + 'k'
  }

  return {
    quantity: count, 
    icon: 'sui_icon_save_completed_16px', 
    tip: `${count} ${language?.SHEIN_KEY_PC_15512}`
  }
}

// 售罄
export const createSoldOut = (goodsInfo, options) => {
  const language = options?.language

  const { stock, is_on_sale  } = goodsInfo || {}

  if (is_on_sale === 0 || stock === 0) {
    return {
      show: true,
      message: language?.SHEIN_KEY_PC_14617 || 'Sold Out'
    }
  } else {
    return {
      show: false
    }
  }
}

